<template>
  <div>
    <title-row :title="$route.name === 'NewUser' ? 'Új felhasználó' : 'Felhasználó szerkesztése'">
      <template #buttons>
        <title-row-button @click="save" :loading="loading">
          <v-icon left>mdi-check</v-icon>
          Mentés
        </title-row-button>
      </template>
    </title-row>

    <loading-overlay v-if="loading" />


    <v-row>
      <v-col :cols="12" :md="6">
        <v-card v-if="form.user.apiuser" class="p-1">
          <v-card-title><v-icon left>mdi-account</v-icon>Alapadatok</v-card-title>
          <v-card-text>
            <v-masked-text-field label="Név" :error-messages="errors.name" v-model="form.user.name"
              :mask="$config.inputMasks.name" filled />
            <v-masked-textarea label="API kulcs" :error-messages="errors.name" v-model="form.user.apikey"
              :mask="$config.inputMasks.name" filled rows="1" auto-grow />
            <v-text-field label="Lejárat dátuma" readonly v-model="form.user.apikey_expire" filled />
          </v-card-text>
        </v-card>
        <v-card v-else class="p-1">
          <v-card-title><v-icon left>mdi-account</v-icon>Alapadatok</v-card-title>
          <v-card-text>
            <v-masked-text-field label="Név" :error-messages="errors.name" v-model="form.user.name"
              :mask="$config.inputMasks.name" filled />
            <v-text-field label="Email" :error-messages="errors.email" v-model="form.user.email" filled />
            <v-masked-text-field label="Jelszó" :error-messages="errors.password" v-model="form.user.password" filled
              :mask="$config.inputMasks.password" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass" autocomplete="new-password" />
            <v-masked-text-field label="Jelszó Újra" :error-messages="errors.password_" v-model="form.user.password_"
              filled :mask="$config.inputMasks.password" :append-icon="showPassAgain ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassAgain ? 'text' : 'password'" @click:append="showPassAgain = !showPassAgain"
              autocomplete="new-password" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12" :md="6">
        <v-card class="p-1">
          <v-card-title><v-icon left>mdi-key-chain</v-icon>Engedélyek</v-card-title>
          <v-card-text>
            <v-switch label="Csoportképzésnél szűrő" v-model="form.user.filter_element" />
            <v-switch label="QR kód beállítás fix 24 óra" v-model="form.user.barcode_period_fix" />
            <v-select filled label="QR kód igénylés csoportok" v-model="form.user.barcode_request_groups"
              :items="usertype_groups" item-text="name" item-value="element_id" multiple chips />
            Jogosultságok
            <v-treeview ref="rightsTree" :items="form.rights" selection-type="leaf" selectable return-object open-all
              selected-color="primary" v-model="selection" shaped transition hoverable multiple-active dense
              @input="rightsInputHandler">
              <template v-slot:prepend="{ item, open }">
                <v-icon>
                  {{
                    item.children
                      ? open
                        ? 'mdi-folder-open-outline'
                        : 'mdi-folder-key-outline'
                      : 'mdi-shield-key-outline'
                  }}
                </v-icon>
              </template>
              <template v-slot:label="{ item }">
                {{ item.name }}
                <span v-if="getRightDependencies(item.id)">
                  (Felülírja: {{ getRightDependencies(item.id) }})
                </span>
              </template>
            </v-treeview>

            <div v-if="form.user.filter_element" class="mt-6">
              Szűrők
              <template v-for="type in filter.element_types">
                <v-autocomplete :label="type.name" :items="filter.elements[type.id]" item-text="name"
                  item-value="element_id" v-model="form.user.filter_elements[type.id]"
                  :value-comparator="$config.comparators.isEqual" multiple chips return-object />
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        user: {},
        rights: [],
      },
      filter: {
        elements: [],
        element_types: [],
      },
      selection: [],
      errors: [],
      usertype_groups: [],
      loading: false,
      showPass: false,
      showPassAgain: false,
      userRights: [],
    };
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    rightsInputHandler(items) {
      for (const item of items) {
        if (item.required_rights) {
          for (const right_id of item.required_rights) {
            const requiredRight = this.findRight('id', this.form.rights, right_id);
            if (requiredRight) {
              this.addRightToSelection(requiredRight);
            }
          }
        }
      }
    },
    getRightsSelection(items) {
      for (const item of items) {
        item.required_rights = JSON.parse(item.required_rights);
        if (item.selected) {
          this.selection.push(item);
        }
        if (item.children) {
          this.getRightsSelection(item.children);
        }
      }
    },
    addRightToSelection(right) {
      if (this.lodash.find(this.selection, { id: right.id })) {
        return;
      }
      this.selection.push(right);
    },
    findRight(key = 'id', rights, value) {
      let result = null;
      for (const right of rights) {
        if (right[key] == value) {
          result = right;
          break;
        }
        if (right.children) {
          result = this.findRight(key, right.children, value);
          if (result) {
            break;
          }
        }
      }
      return result;
    },
    getRightDependencies(id) {
      const dependencyOf = [];
      for (const item of this.selection) {
        if (item.required_rights && item.required_rights.includes(Number(id))) {
          dependencyOf.push(item);
        }
      }
      return dependencyOf.map((e) => e.name).join(', ');
    },
    async fetchUser() {
      try {
        this.loading = true;
        const response = await this.$http.get(`users/details/${this.$route.params.id || 0}`);
        response.user.password_ = response.user.password;
        this.form.user = response.user;
        this.form.rights = response.rights;
        this.usertype_groups = response.usertype_groups;
        this.filter = response.filter;
        this.selection = [];
        this.getRightsSelection(this.form.rights);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        this.errors = {};
        this.loading = true;
        this.form.rights = this.selection;
        const response = await this.$http.post('users/save/', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          if (this.$route.name !== 'UserEditor') {
            this.$router.push({ name: 'UserEditor', params: { id: response.user.element_id } });
          }
          this.fetchUser();
          this.$dialog.notify.success('Sikeres mentés!');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root {
  margin: 0 !important;
}

.v-treeview-node__root {
  padding: 0 !important;
  min-height: 32px !important;
}
</style>
